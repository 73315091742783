$base-color: #4ec0b0;
$contrast-color: #FF6C5C;
$input-error-color: #FF5252;

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

/*@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);*/

@import "resources/assets/sass/text.scss";
@import "resources/assets/sass/helpers.scss";
@import "resources/assets/sass/buttons.scss";
@import "resources/assets/sass/forms.scss";
@import "resources/assets/sass/card.scss";
/*@import "resources/assets/sass/responsive.scss";*/

a, a:link, a:hover, a:visited {
    color: $base-color;
}

#coupon-modal .modal-body {
    text-align: center;
}

body {
    padding-top: 50px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 300;
    color: #666;
    background: #eee;
}

body.iframe {
    background: #fff;
}

section.content {
    margin-top: 20px;
}

footer.footer {
    margin-bottom: 10px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

footer.footer .left {
    text-align: center;
}

footer.footer .right {
    text-align: center;
}

img {
    display: block;
    height: auto;
    max-width: 100%;
}

.arrow {
    bottom: 10px;
    left: 50%;
    margin-left: -20px;
    position: absolute;
}

.arrow > a {
    background: url(/img/arrow-down.svg) center no-repeat;
    background-size: 20px;
    color: #fff;
    display: block;
    height: 30px;
    width: 40px;
}

.search-form {
    padding-top: 15px;
    padding-bottom: 20px;
    background: #fff;
    box-shadow: 0px 0 5px 0px rgba(0,0,0,0.2);
}

.search-form .form-group:last-child {
    margin-bottom: 0;
}

.search-form h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 200;
}

.bounce {
    -webkit-animation: pulse 1s 4;
    animation: pulse 1s 4;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.site-header {
    /*height: 100vh;
    min-height: 622px;*/
    height: auto;
    padding: 40px 0;
    color: #000;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(255,255,255,0.8);
    background-image: url(/img/header-bg-5.jpg);
    background-color: #fff;
    background-size: auto auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /*box-shadow: inset 0px -10px 10px 0px rgba(0,0,0,0.1);*/
    /*background: #2b4959;
    background-image: linear-gradient(135deg, #464672, #2b4959 40%, #2b4959);*/
}

.partners {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
}

.partners.top {
    margin-top: 0;
    margin-bottom: 30px;
}

.partners > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;
}

.partners > ul > li {
    display: inline-block;
    padding: 10px;
}

.partners > ul > li > a {
    display: block;
    line-height: 30px;
    height: 30px;
}

.partners > ul > li > a > img {
    max-height: 30px;
    margin: auto;
    vertical-align: middle;
    display: inline-block;
}

/**
* ##############################################################
* Error messages
* ##############################################################
*/
.input-error {
    display: none;
    position: absolute;
    top: -36px;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    background: $input-error-color;
}

.input-error > .error-arrow {
    position: absolute;
    left: 7px;
    bottom: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-top: 7px solid $input-error-color;
}

.input-error > a.error-close,
.input-error > a.error-close:hover {
    float: right;
    padding: 0 7px 0 7px;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    text-decoration: none;
}

.input-error > .error-inner {
    float: left;
    padding: 5px 7px 5px 7px;
}

.form-group.error .input-error {
    display: block;
}

.btn > .ajax-loader {
    display: none;
    float: left;
    width: 16px;
    height: 11px;
    margin-top: 4px;
    margin-right: 10px;
    background-image: url(/img/ajax-btn-loader-white.gif);
}

/**
* ##############################################################
* Bouncing Arrow
* ##############################################################
*/

/*
@import "compass/css3";

@include keyframes(bounce) {
  0%, 20%, 50%, 80%, 100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-30px));
  }
  60% {
    @include transform(translateY(-15px));
  }
}

.arrow {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left:-20px;
  width: 40px;
  height: 40px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
}

.bounce {
  @include animation(bounce 2s infinite);
}
*/

/**
* ##############################################################
* Navbar reset
* ##############################################################
*/

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: $base-color;
    background-color: transparent;
}

.navbar {
    /*min-height: 80px;
    border-bottom: 1px dashed #317485;*/
    background: #fff;
}

.navbar a {
    color: #fff;
}

.navbar-nav > li > a {
    /*padding-top: 10px;
    padding-bottom: 10px;*/
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    color: $base-color;
}

.navbar-brand {
    padding: 0 15px;
    color: #fff;
    line-height: 50px;
}

.navbar-brand > img {
    float: left;
    margin-right: 10px;
}

.navbar-brand > .name {
    float: left;
}

.navbar-toggle {
    border-radius: 0;
}

.navbar-toggle .icon-bar {
    background-color: #fff;
}

/**
* ##############################################################
* Offers
* ##############################################################
*/

.masonry-grid-item {
    width: 100%;
}

.offers .offer {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.offers .offer.box {
    padding: 20px;
}

.offers .offer:hover {
    box-shadow: 0 1px 10px 0 #ccc;
}

.offers .offer > header > .logo > img {
    margin: 0 auto 20px auto;
}

.offers .offer > header > h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
}

.offers .offer > header > .category {
    font-size: 12px;
    color: #999;
}

.offers .offer > .body {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.offers .offer > .body > .heading {
}

.single-offer > .left > .box {
    font-size: 14px;
    line-height: 1.4;
    color: #666;
}

.single-offer > .left > .box {
    padding: 20px;
    font-size: 14px;
}

h2.border {
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 2px;
    color: $base-color;
    border-bottom: 1px solid #ddd;
}

h2.border > span {
    border-bottom: 1px solid $base-color;
    border-bottom-width: 1px;
}

.single-offer > .left > .box > h3 {
    margin: 20px 0;
    color: #666;
}

.single-offer > .right > .box {
    padding: 0;
    font-size: 12px;
}

.single-offer > .right > .box > .logo {
    padding: 20px;
    text-align: center;
}

.single-offer > .right > .box > .logo > img {
    display: inline;
    max-width: 100%;
    height: auto;
}

.single-offer > .right > .box > .offer-box {
    padding: 20px;
    border-top: 1px solid #ddd;
}

.single-offer > .right > .box > .offer-box:first-child {
    border-top: none;
}

.single-offer > .right > .box > .offer-box h2 {
    margin-top: 0;
    font-size: 18px;
}

.offer-box .error {
    font-size: 14px;
    color: #a94442;
}

.offer-box.hidden-message {
    color: #41A2D8;
    background: #DFF1FB;
}

.single-offer p:last-child {
    margin-bottom: 0;
}

.single-offer > .right > .box > a.link {
    display: block;
    font-size: 14px;
    text-decoration: none;
}

.single-offer > .right > .box > a.link:hover {
    text-decoration: none;
    background: #e6f7f4;
}

.single-offer > .right > .box > a.link.coupon-btn {

}

.single-offer > .right > .box > a.link.coupon-btn:hover {
    text-decoration: none;
}

.modal-content {
    border-radius: 0;
}

.btn {
    font-weight: 300;
    text-shadow: none;
}

.btn.btn-rounded {
    border-radius: 17px;
}

.btn-lg.btn-rounded {
    border-radius: 23px;
}

.form-control,
.btn,
.well {
    border-radius: 0;
}

label {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 300;
}

select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
    border-radius: 0 !important;
}

/*
select,
select.form-control {
    -webkit-appearance: none;
    background:url("data:image/svg+xml;utf8,<svg xmlns='https://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='47.25,15 45.164,12.914 25,33.078 4.836,12.914 2.75,15 25,37.25'/></svg>") right no-repeat;
    background-color: #fff;
    background-transparency: 0.5;
    background-position: right 10px top 10px;
    background-size: 14px 14px;
}

select.input-lg,
select.form-control.input-lg {
    background-position: right 10px top 15px;
}
*/

select.input-lg {
    line-height: 26px;
}

select.input-sm {
    line-height: 18px;
}

#coupon-modal .modal-body {
    padding: 20px;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
    border-radius: 0;
}

.coupon {
    margin-bottom: 16px;
    padding: 5px;
    font-size: 20px;
    font-weight: 700;
    border: 1px solid #ddd;
    background: #eee;
}

.alert {
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 0;
}
