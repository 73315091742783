body.card {
  padding: 5%;
  color: #fff;
  text-align: center;
}

#card {
  width: 500px;
  height: 300px;
  margin: 40px auto;
}

.card-front, .card-back {
  position: relative;
  padding: 20px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  color: #fff;
  border-radius: 10px;
  background: #2b4959;
}

.card-back {
  font-size: 13px;
}

.card-front > .logo {
  text-align: center;
}

.card-front > .logo > img {
  margin: 30px auto 0;
  width: 88px;
  height: 80px;
}

.card-front > .logo > h1 {
  margin: 20px 0 10px;
  font-size: 50px;
  font-weight: 200;
}

.card-back .content {
  margin-bottom: 40px;
  overflow: hidden;
}

.card-back .heading {
  float: left;
  width: 60%;
  text-align: left;
  font-size: 16px;
  line-height: 1.2;
}

.card-back .address {
  float: right;
  width: 40%;
  text-align: right;
}

.card-number {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 24px;
  border-radius: 2px;
  color: #333;
  background: #fff;
}

.card-number-label {
  text-align: left;
}

.card-info {
  font-size: 13px;
}

.card-back .footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}
