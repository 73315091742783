/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

    h1, .h1, h2, .h2, h3, .h3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h2, .h2 {
        font-size: 24px;
    }

    h2.border > span {
        padding-bottom: 8px;
        border-bottom-width: 1px;
    }

    h3, .h3 {
        font-size: 20px;
    }

    .site-header {
        text-align: left;
        background-position: top right;
    }

    .site-header h1 {
        font-size: 50px;
    }

    .site-header h2 {
        font-size: 30px;
    }

    .site-header h3 {
        font-size: 20px;
    }

    .search-form {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .search-form h3 {
        font-size: 24px;
    }

    .search-form .form-group {
        margin-bottom: 0;
    }

    .offers {
        margin-left: -20px;
    }

    .masonry-grid-item {
        width: 226px;
        margin-left: 20px;
    }

    .masonry-grid-item.highlighted {
        width: 472px;
        margin-left: 20px;
    }

    .single-offer > .right > .box {
        font-size: 14px;
    }

    h2.border {
        margin-top: 0;
        margin-bottom: 40px;
        padding-bottom: 9px;
        font-size: 20px;
    }

    .text-center-heading {
        height: 13px;
        margin-bottom: 40px;
        font-size: 20px;
    }

    section.content {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .partners > ul > li {
        padding: 20px;
    }

    .partners > ul > li > a {
        line-height: 28px;
        height: 28px;
    }

    .partners > ul > li > a > img {
        max-height: 28px;
    }

    footer.footer {
        margin-bottom: 30px;
        padding-top: 40px;
    }

    footer.footer .left {
        text-align: left;
    }

    footer.footer .right {
        text-align: right;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .site-header {
        padding: 80px 0;
    }

    .site-header h1 {
        font-size: 60px;
    }

    .partners > ul > li {
        padding: 20px;
    }

    .partners > ul > li > a {
        line-height: 28px;
        height: 28px;
    }

    .partners > ul > li > a > img {
        max-height: 28px;
    }
    .masonry-grid-item {
        width: 300px;
    }

    .masonry-grid-item.highlighted {
        width: 620px;
    }
    .single-offer > .left > .box {
        padding: 40px;
        font-size: 16px;
    }

    .single-offer > .right > .box > a.link {
        font-size: 18px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    .partners > ul > li > a {
        line-height: 35px;
        height: 35px;
    }

    .partners > ul > li > a > img {
        max-height: 35px;
    }

    .masonry-grid-item {
        width: 270px;
    }

    .masonry-grid-item.highlighted {
        width: 560px;
    }
}
