label {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  /*text-transform: uppercase;*/
}

.county-loader,
.modal-loader {
  display: none;
  width: 26px;
  height: 34px;
  background-image: url("/img/ajax-loader-32.svg");
  background-position: center center;
  background-repeat: no-repeat;
    background-size: 26px 26px;
}

.county-loader-lg,
.modal-loader-lg {
    display: none;
    width: 32px;
    height: 46px;
    background-image: url("/img/ajax-loader-32.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
}

.county-loader-sm,
.modal-loader-sm {
    display: none;
    width: 20px;
    height: 30px;
    background-image: url(/img/ajax-loader-32.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}

.county-loader {
  position: absolute;
  right: 42px;
}

.county-loader-sm {
    position: absolute;
    right: 20px;
}

.modal-loader {
  margin: 10px auto;
}

.county-loader.show,
.modal-loader.show {
  display: block;
}

.modal-footer .checkbox {
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 14px;
}

.modal-footer label {
  text-transform: none;
}
