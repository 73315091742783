/**
* ##############################################################
* Headings
* ##############################################################
*/

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h2, .h2 {
    font-size: 18px;
}

h3, .h3 {
    font-size: 16px;
}

.site-header h1 {
    margin-top: 0;
    font-size: 30px;
    font-family: Open Sans, sans-serif;
    font-weight: 200;
}

.site-header h1 a {
    color: #000;
    text-decoration: none;
}

.site-header h1 > a > span {
    font-weight: 600;
}

.site-header h2 {
    font-size: 18px;
    font-weight: 200;
}

.site-header h3 {
    margin-top: 0;
    font-size: 14px;
    font-weight: 300;
}

.text-center-heading {
    height: 10px;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.text-center-heading > span {
    padding: 0 20px;
    background: #eee;
}

.site-header .text-center-heading {
    color: #fff;
    border-bottom: 1px dashed #317485;
}

.site-header .text-center-heading > span {
    padding: 0 20px;
    background: #2b4959;
}
