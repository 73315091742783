
.btn {
  /*font-family: 'Montserrat', sans-serif;*/
  text-transform: uppercase;
  color: #fff !important;
  font-weight: normal;
  /*border-radius: 2px;*/
  /*-webkit-box-shadow: 0 8px 0 -3px #3a7667;
          box-shadow: 0 8px 0 -3px #3a7667;*/
}

.btn-xl {
  padding: 20px 40px;
  font-size:20px;
}

.site-header .btn-xl {
  margin-top: 40px;
}

.btn-default, .btn-primary, .btn-info, .btn-success, .btn-warning, .btn-danger {
  border-color: transparent !important;
}

.btn-default {

}

.btn-primary {

}

.btn-info {

}

.btn-success {

}

.btn-warning {

}

.btn-danger {

}

.btn-green {
  background-color: $base-color;
}

.btn-green:hover, .btn-green:focus {
  color: #fff;
  background-color: darken($base-color, 6%);
}

.btn-red {
  background-color: $contrast-color;
}

.btn-red:hover, .btn-red:focus {
  color: #fff;
  background-color: darken($contrast-color, 6%);
}
